import heic2any from 'heic2any'

export async function convertHeicToJpeg(file) {
  let imagem = file
  let arquivo = file.name.toLowerCase()
  try {
    const imagemConvertidaBlobArray = await heic2any({
      blob: imagem,
      toType: 'image/jpeg',
    })
    // Certifique-se de que estamos trabalhando com um Blob único, mesmo que a biblioteca retorne um array
    const imagemConvertidaBlob =
      imagemConvertidaBlobArray instanceof Blob ? imagemConvertidaBlobArray : imagemConvertidaBlobArray[0]

    let resultado = new File([imagemConvertidaBlob], arquivo.replace('.heic', '.jpeg'), { type: 'image/jpeg' })
    return resultado
  } catch (error) {
    console.error("Erro ao converter imagem '.heic' para '.jpeg,' tente anexar uma imagem em outro formato.")
  }
}
