import ImageCompressor from 'image-compressor'

export async function compactImagem(file) {
  const fileConverted = await handleFileChange(file)
  return fileConverted
}

async function handleFileChange(file) {
  if (file) {
    try {
      const compressedFile = await compressFile(file)
      return URL.createObjectURL(compressedFile)
    } catch (error) {
      console.error('Error compressing file:', error)
    }
  }
}

async function compressFile(file) {
  if (file.type.includes('image')) {
    return await compressImage(file)
  } else {
    // Handle other file types here (e.g., docx)
    console.warn('Unsupported file type:', file.type)
    return file
  }
}

async function compressImage(file) {
  const options = {
    quality: 0.7, // Adjust quality as needed
    maxWidth: 800, // Adjust max width as needed
    maxHeight: 800, // Adjust max height as needed
  }

  const compressedFile = await ImageCompressor(file, options)
  return compressedFile
}
