import useJwt from '@/auth/jwt/useJwt'
import { AxiosInstance } from 'axios'
import { Configuration} from '.'

const config = new Configuration({
    accessToken: useJwt.getToken(),
  })

export function fetcherFactory<T extends (...args: any[]) => any>(func: T, ...args: Parameters<T>): ReturnType<T> {
    return func(config, "", useJwt.axiosIns);
}