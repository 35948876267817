export const getMediaError = (error: any) => {
  return error.name === 'NotFoundError'
    ? 'Os dispositivos de câmera e ou microfone não foram encontrados.'
    : error.name === 'NotAllowedError'
    ? 'Permissão para acessar a câmera e ou microfone foi negada.'
    : error.name === 'SecurityError'
    ? 'Erro de segurança ao acessar a câmera e o microfone.'
    : error.name === 'AbortError'
    ? 'A solicitação de acesso à câmera e ou microfone foi cancelada.'
    : error.name === 'NotReadableError'
    ? 'Dispositivos de mídia, câmera e ou microfone, estão indisponíveis ou não podem ser acessados.'
    : error.name === 'OverconstrainedError'
    ? 'As configurações de mídia especificadas pelo sistema não podem ser atendidas por este dispositivo.'
    : error.name === 'TypeError'
    ? 'Tipo inválido de configuração especificada para câmera e ou microfone.'
    : 'Erro desconhecido ao acessar a câmera e ou microfone.'
}
