import { PDFDocument, rgb } from 'pdf-lib'
import pako from 'pako'

export async function compactPDF(file) {
  const fileConverted = await handleFileChange(file)
  return fileConverted
}

async function handleFileChange(file) {
  if (file) {
    try {
      const compressedFile = await compressFile(file)
      return URL.createObjectURL(compressedFile)
    } catch (error) {
      console.error('Error compressing file:', error)
    }
  }
}

async function compressFile(file) {
  if (file.type === 'application/pdf') {
    return await compressPDF(file)
  } else {
    // Handle other file types here (e.g., docx)
    console.warn('Unsupported file type:', file.type)
    return file
  }
}

async function compressPDF(file) {
  if (file) {
    const pdfData = await file.arrayBuffer()
    const pdfDoc = await PDFDocument.load(pdfData)

    // Perform PDF manipulation here using the pdf-lib library
    // For example, you can remove unnecessary elements or reduce image quality

    const compressedPdfData = await pdfDoc.save()
    const compressedPdfBlob = new Blob([compressedPdfData], { type: 'application/pdf' })

    if (compressedPdfBlob.size <= 5 * 1024 * 1024) {
      // PDF is already compressed and under 5MB
      console.error('PDF is compressed and under 5MB.')
      return
    }

    // Perform additional compression using the pako library
    const compressedData = pako.deflate(compressedPdfData)
    const compressedBlob = new Blob([compressedData], { type: 'application/pdf' })

    if (compressedBlob.size <= 5 * 1024 * 1024) {
      // Successfully compressed PDF under 5MB
      const compressedPdfUrl = URL.createObjectURL(compressedBlob)
      window.open(compressedPdfUrl)
    } else {
      console.error('Compression failed. PDF still over 5MB.')
    }
  }
}
