<template>
  <div>
    <!--Step Selfie-->
    <b-card>
      <h4
        class="rounded bg-primary text-white"
        style="text-align: center; font-size: 20px; font-weight: bold; margin-bottom: 25px; padding: 10px 8px 8px 15px"
      >
        Selfie - Foto de rosto
      </h4>
      <validation-observer ref="fotoRules" tag="form">
        <h4 class="text-center">Tire uma foto sua</h4>
        <b-row>
          <b-col md="12">
            <div class="profile-img" style="text-align: center; right: 20px; top: 10px">
              <span v-if="!hasSelfie" class="b-avatar badge-secondary rounded" style="width: 360px; height: 320px">
                <feather-icon icon="UserIcon" fill="currentColor" size="80" />
              </span>
              <img v-if="hasSelfie" :src="selfie.s3url || selfie.foto" class="img-fluid rounded" style="max-height: 320px" />
              <div style="text-align: center">
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-success"
                  class="btn-icon rounded-circle"
                  @click="capturarFoto()"
                  title="Tirar foto"
                  v-if="!hasSelfie && this.showRemoveSelfieButton"
                >
                  <feather-icon icon="CameraIcon" /> Tirar Foto
                </b-button>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-danger"
                  class="btn-icon rounded-circle"
                  @click="limparFoto()"
                  v-if="hasSelfie && this.showRemoveSelfieButton"
                >
                  <feather-icon icon="XSquareIcon" /> Limpar Foto
                </b-button>
                <b-form-file
                  v-model="fileIndividual"
                  ref="fileIndividual"
                  accept=".pdf,.jpg,.heic,.png"
                  @change="checkTwoConfirm"
                  style="display: none"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>

    <b-modal
      id="modal-foto"
      ref="modal-foto"
      centered
      size="sm"
      title="Captura de Foto"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      style="position: Absolute"
      @hidden="stopCameraStream"
      @close="stopCameraStream"
    >
      <div class="camera-canvas">
        <video v-if="isCameraOpen" ref="camera" :width="canvasWidth" :height="canvasHeight" autoplay></video>
        <canvas v-show="!isCameraOpen" ref="canvas" :width="canvasWidth" :height="canvasHeight"></canvas>
      </div>
      <template>
        <b-button size="sm" variant="primary" v-show="isCameraOpen" @click="capture()"> Tirar Foto </b-button>
        <b-button size="sm" variant="success" v-show="!isCameraOpen" @click="checkTwoConfirm()" :disabled="loading">
          Confirmar Foto
        </b-button>
        <b-button size="sm" variant="danger" @click="toggleCamera()" v-show="!isCameraOpen"> Limpar Foto </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import {
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BRow,
    BCol,
    BCard,
    BDropdown,
    BDropdownItem,
    BModal,
    VBModal,
    BSpinner,
    BFormFile,
  } from 'bootstrap-vue'
  import { ValidationObserver } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'
  import Swal from 'sweetalert2/dist/sweetalert2.js'
  import {
    createAnexoRegistroReconhecimentoFacial,
    deleteAnexoRegistroReconhecimentoFacial,
    getReconhecimentoFacial,
    createRegistroReconhecimentoFacial,
    VerificaRostoImagem,
  } from './utils/reconhecimentoFacialAnexos'
  import { convertHeicToJpeg } from './utils/convert-imagem/convert-jpeg'
  import { heightTransition } from '@core/mixins/ui/transition'
  import { getMediaError } from './utils/getMediaError'

  export default {
    components: {
      BForm,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BButton,
      BRow,
      BCol,
      BCard,
      BDropdown,
      BDropdownItem,
      BModal,
      ValidationObserver,
      BSpinner,
      BFormFile,
      convertHeicToJpeg,
    },
    directives: {
      Ripple,
      'b-modal': VBModal,
    },
    mixins: [heightTransition],
    computed: {
      hasSelfie() {
        return this.selfie?.id ? true : false
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,

        fileIndividual: null,
        refInputEl: null,

        selfie: {},
        dados: {},
        pesquisar: { tipoOpcoes: 1, descricao: '', perPage: 10, currentPage: 1 },
        reconhecimentoFacial: {
          anexoRegistro: [],
        },

        docAnexados: [],
        tiposDocumentos: [],
        tiposDocumentosFiltrados: [],

        canvasHeight: 255,
        canvasWidth: 340,

        isCameraOpen: false,
        passaSelfie: false,
        showRemoveSelfieButton: true,
        showBotaRecad: true,
        loading: false,
        modalSelfie: true,
      }
    },
    async created() {
      this.dados = this.userData
      this.iniciarReconhecimentoFacial()
    },
    methods: {
      sweetAlert(msg) {
        Swal.fire({
          title: msg,
          timer: false,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
          },
          willClose: () => {
            clearInterval(false)
          },
          allowOutsideClick: false, // Bloqueia o fechamento ao clicar fora do SweetAlert
        })
      },
      async iniciarReconhecimentoFacial() {
        const reconhecimentoFacial = await getReconhecimentoFacial(this.dados.institutoId, this.dados.cpf, true)
        if (reconhecimentoFacial.data.length == 0) {
          const dadosSegurado = {
            institutoId: this.dados.institutoId,
            cpf: this.dados.cpf,
            nome: this.dados.nomeCompleto,
            selfie: null,
            docAnexados: null,
          }

          const reconhecimentoFacialPost = await createRegistroReconhecimentoFacial(dadosSegurado)
          this.reconhecimentoFacial = reconhecimentoFacialPost.data
        } else {
          this.reconhecimentoFacial = reconhecimentoFacial.data[0]
        }
        this.addSelfie(this.reconhecimentoFacial)
      },
      addSelfie(reconhecimentoFacial) {
        const selfie = reconhecimentoFacial.anexoRegistroReconhecimentoFacial?.find((annex) => annex.tipo == 0)
        if (selfie) {
          this.passaSelfie = true
          this.selfie = {
            id: selfie?.id,
            foto: selfie?.foto,
            s3url: selfie?.s3url,
          }
        }
      },
      clearSelfie() {
        this.sweetAlert('Aguarde... Estamos excluindo a foto.')
        deleteAnexoRegistroReconhecimentoFacial(this.selfie.id)
          .then((res) => {
            if (res.status === 200) {
              this.$message.success('Foto excluída com sucesso!')
              this.passaSelfie = false
              this.selfie = undefined
            } else {
              this.$message.error('Erro ao excluir a foto.')
            }
            return
          })
          .catch((error) => {
            console.error('[clearSelfie]', error)
            this.$message.error('Erro ao excluir a foto.')
            return
          })
          .finally(() => {
            Swal.close()
          })
      },
      capturarFoto() {
        //no app não está funcionando a camera, por isso o anexo para mobile
        var innerWidth = window.innerWidth
        if (innerWidth > 1280) {
          this.modalSelfie = true
          this.toggleCamera()
          this.$refs['modal-foto'].show()
        } else {
          this.$refs.fileIndividual.$el.childNodes[0].click()
        }
      },
      toggleCamera() {
        if (this.isCameraOpen) {
          this.isCameraOpen = false
          this.stopCameraStream()
        } else {
          this.isCameraOpen = true
          this.startCameraStream()
        }
      },
      startCameraStream() {
        const constraints = (window.constraints = {
          audio: false,
          video: true,
        })
        try {
          navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
              this.$refs.camera.srcObject = stream
            })
            .catch((error) => {
              console.error(error)
              const erroMensage = getMediaError(error)
              this.$message.error('Erro: ' + erroMensage)
            })
        } catch (e) {
          this.$message.error('Um erro ocorreu: ' + e.message)
        }
      },
      stopCameraStream() {
        this.isCameraOpen = false
        var tracks
        if (this.$refs.camera) {
          tracks = this.$refs.camera.srcObject.getTracks()
          tracks.forEach((track) => {
            track.stop()
          })
        }
      },
      capture() {
        const FLASH_TIMEOUT = 50
        let self = this
        setTimeout(() => {
          const context = self.$refs.canvas.getContext('2d')
          context.drawImage(self.$refs.camera, 0, 0, this.canvasWidth, this.canvasHeight)
          const dataUrl = self.$refs.canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream')
          self.isCameraOpen = false
          self.stopCameraStream()
        }, FLASH_TIMEOUT)
      },
      limparFoto() {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente excluir a foto?',
          icon: 'warning',
          showCancelButton: true,

          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.passaSelfie = false
            this.clearSelfie()
            return
          }
        })
      },
      checkFileExtension(e) {
        if (e?.target?.files[0]) {
          const arquivo = e.target.files[0].name.toLowerCase()
          const extensoesPermitidas = ['.jpeg', '.jpg', '.heic', '.png']
          const extensao = arquivo.substr(arquivo.lastIndexOf('.'))

          if (!extensoesPermitidas.includes(extensao)) {
            this.$message.info('Extensão inválida! Só é aceito fotos no formato: .jpeg, .heic, .jpg ou .png')
            return false
          }
        }
        return true
      },
      async checkTwoConfirm(e) {
        if (!this.checkFileExtension(e)) {
          return
        }

        if (!this.modalSelfie) {
          return
        }

        const innerWidth = window.innerWidth
        const self = this
        let dataUrl = null

        if (innerWidth > 1280) {
          dataUrl = self.$refs.canvas.toDataURL('image/jpeg', 0.5).replace('image/jpeg', 'image/octet-stream')
          self.confirmarFoto(dataUrl)
        } else {
          let arquivo = e.target.files[0]
          const nomeArquivo = e.target.files[0].name
          const extensao = nomeArquivo.substr(nomeArquivo.lastIndexOf('.'))
          var reader = new FileReader()

          if (extensao === '.heic' || extensao === '.HEIC') {
            try {
              await this.sendPhotoExtensionHeic(arquivo, reader, dataUrl, self)
            } catch (error) {
              this.$message.info("Erro ao converter imagem '.heic' para '.jpeg,' tente anexar uma imagem em outro formato.")
            }
          } else {
            await this.sendPhoto(arquivo, reader, dataUrl, self)
          }
        }
      },
      async sendPhoto(arquivo, reader, dataUrl, self) {
        reader.readAsDataURL(arquivo)
        reader.onload = function () {
          dataUrl = reader.result
          self.confirmarFoto(dataUrl)
        }
        reader.onerror = function (error) {
          console.error(error)
        }
      },
      async sendPhotoExtensionHeic(arquivo, reader, dataUrl, self) {
        arquivo = await convertHeicToJpeg(arquivo)
        reader.readAsDataURL(arquivo)
        reader.onload = function () {
          dataUrl = reader.result
          self.confirmarFoto(dataUrl)
        }
        reader.onerror = function (error) {
          console.error(error)
        }
      },
      dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], filename, { type: 'image/png' })
      },
      async createAnexoRegistro(aux) {
        Swal.close()
        this.sweetAlert(`Aguarde... Salvando a foto.`)

        const res = await createAnexoRegistroReconhecimentoFacial(aux).catch((error) => {
          console.error('error', error)
          Swal.close()
          this.$refs['modal-foto'].hide()
        })

        if (!res.data.success && !res.data.id) {
          this.dontPassSelfie(res.data.msg)
          return false
        }
        return res
      },
      async validFaceInSelfie(base64) {
        this.sweetAlert(`Aguarde... Estamos validando a foto tirada.`)
        const response = await VerificaRostoImagem({ base64: base64 }).catch((error) => {
          this.$message.warning(`Falha ao validar sua self. Favor, tente retirar uma nova foto.`)
          console.error(error)
          this.loading = !this.loading
          Swal.close()
        })

        if (!response.data.success) {
          this.dontPassSelfie(response.data.msg)
          return false
        }
        return true
      },
      async dontPassSelfie(msg) {
        this.passaSelfie = false
        this.selfie = undefined
        Swal.close()
        this.$refs['modal-foto'].hide()
        this.$message.warning(`Foto inválida. ${msg}`)
      },
      async confirmarFoto(dataUrl) {
        const response = await this.validFaceInSelfie(dataUrl)
        if (!response) return

        this.$message.success(`Foto válida!`)

        //imagem com 1 rosto humano
        const res = await this.createAnexoRegistro({
          foto: dataUrl,
          nome: 'Selfie',
          tipo: 0,
          registroReconhecimentoFacialId: this.reconhecimentoFacial.id,
          institutoId: this.userData?.institutoSelecionado ?? this.dados?.institutoId,
          cpf: this.dados.cpf,
          origemDocumento: 'Aplicativo',
        })

        if (!res) return

        this.passaSelfie = true
        this.selfie = res.data
        this.$message.success(`Foto salva com sucesso!`)
        Swal.close()
        this.$refs['modal-foto'].hide()
      },
    },
    watch: {
      selfie(novo, atual) {
        if (this.selfie && this.passaSelfie) {
          this.$emit('selfieValidada', this.selfie)
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';

  .disabled {
    background-color: lightgray;
    padding: 0.7rem;
    border-radius: 0.3rem;
    font-weight: 500;
  }
</style>
