<template>
  <div>
    <b-card-title title-tag="h2" class="font-weight-bold text-center mb-1"> Lista de Parcelas </b-card-title>

    <b-card-text v-if="!listaDePropostas.length">
      <b-row> Nenhuma proposta encontrada </b-row>
    </b-card-text>

    <b-table
      small
      v-show="listaDePropostas.length > 0"
      striped
      responsive
      id="tablePropostas"
      ref="tablePropostas"
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="this.listaDePropostas"
      :fields="this.fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :busy="isBusy"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando Proposta(s)...</strong>
        </div>
      </template>

      <template #cell(visualizar)="row">
        <b-button
          variant="primary"
          size="sm"
          :disabled="row.item.statusFinanceiro == 'Cancelado'"
          @click="VisualizarContrato(row.item.id)"
          ><feather-icon :icon="'EyeIcon'"
        /></b-button>
      </template>

      <template #cell(valorComIOF)="row">
        <p v-if="row.item.valorComIOF">{{ formatarValor(row.item.valorComIOF) }}</p>
        <p v-else-if="!row.item.valorComIOF && row.item.valorIOF">
          {{ formatarValor(Number(row.item.valorBaseCalculo) + Number(row.item.valorIOF)) }}
        </p>
        <p v-else>{{ formatarValor(row.item.valorBaseCalculo) }}</p>
      </template>

      <template #cell(opcoes)="row">
        <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              title="Este botão contém as opções disponíveis para este registro"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="visualizarProposta(row.item)">
            <feather-icon icon="EyeIcon" size="16" />
            <span class="align-middle ml-50">Visualizar Proposta</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template #cell(statusFinanceiro)="row">
        <b-badge pill :variant="status[0][row.item.statusFinanceiro]">
          {{ formatarStatus(row.item.statusFinanceiro) }}
        </b-badge>
      </template>
    </b-table>

    <b-card-body v-if="this.listaDePropostas.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Por Página"
        style="align-items: center"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="this.listaDePropostas.length"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>
    <b-modal id="modal-visualizar-contrato" ref="modal-visualizar-contrato" centered title="Visualizar Contrato" hide-footer>
      <b-table :items="parcelasPropostas" :fields="fieldsParcelasContrato" :busy="isBusy" small striped responsive>
        <template #cell(pagamento)="row">
          <b-badge v-if="row.item.pagamento" pill variant="info"> Pago </b-badge>
          <b-badge v-else pill variant="warning"> A Vencer </b-badge>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarData, formatarValor, formatarValorPorcentagem } from '@/libs/utils'
  import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
  import VisualizarContrato from '../../financeiro/contrato/VisualizarContrato.vue'

  export default {
    components: { FeatherIcon },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        formatarValor,
        formatarData,
        isBusy: false,
        listaDePropostas: [],
        pesquisar: {
          statusOpcoes: '',
          descricao: '',
        },
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        sortBy: 'codigo',
        sortDesc: true,
        sortDirection: 'desc',
        fields: [
          { id: 'visualizar', key: 'visualizar', label: 'Visualizar', sortable: false },
          { id: 'codigo', key: 'codigo', label: 'Código', sortable: true },
          {
            id: 'valorComIOF',
            key: 'valorComIOF',
            label: 'Valor',
            sortable: true,
            formatter: (value) => {
              return formatarValor(value)
            },
          },
          {
            id: 'taxas',
            key: 'taxas',
            label: 'Taxa',
            sortable: false,
            formatter: (value) => {
              return formatarValorPorcentagem(value)
            },
          },
          { id: 'statusFinanceiro', key: 'statusFinanceiro', label: 'Status', sortable: false },
          { id: 'observacao', key: 'motivoRecusaContrato', label: 'Observação' },
          { id: 'baixarContrato', key: 'baixarContrato', label: 'Contrato' },
        ],
        fieldsParcelasContrato: [
          { id: 'parcela', key: 'parcela', label: 'Parcela', sortable: true },
          {
            id: 'valor',
            key: 'valor',
            label: 'Valor',
            sortable: true,
            formatter: (value) => {
              return formatarValor(value)
            },
          },
          {
            id: 'vencimento',
            key: 'vencimento',
            label: 'Vencimento',
            sortable: true,
            formatter: (value) => {
              return formatarData(value)
            },
          },
          { id: 'pagamento', key: 'pagamento', label: 'Status', sortable: true },
        ],
        parcelasPropostas: [],
        status: [
          {
            Pendente: 'warning',
            PendenteDePagamento: 'secondary',
            PendenteDeDocumento: 'secondary',
            Consolidado: 'success',
            Quitado: 'info',
            Cancelado: 'danger',
            Renegociada: 'primary',
          },
        ],
      }
    },
    mounted() {
      this.buscarParcelas()
    },
    methods: {
      retornarAbaMenu() {
        this.$emit('retornarAbaInicial')
      },
      async buscarParcelas() {
        this.isBusy = true
        let filtros = {
          institutoId: this.userData.institutoId,
          cpf: this.userData.cpf,
        }
        useJwt
          .post('proposta/GetPesquisarPropostasExternas', filtros)
          .then((response) => {
            this.listaDePropostas = response.data

            if (this.listaDePropostas.length == 0) {
              this.$message.info('Nenhum Contrato Encontrado')
            }
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao buscar Contratos')
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      formatarStatus(status) {
        switch (status) {
          case 'ContratoCancelado':
            return 'Contrato Cancelado'
          case 'ContratoGerado':
            return 'Contrato Gerado'
          default:
            return status
        }
      },

      VisualizarContrato(id) {
        useJwt.post(`financeiro/GetbuscaParcelas/${id}`).then((response) => {
          this.parcelasPropostas = response.data
          this.$refs['modal-visualizar-contrato'].show()
        })
      },
    },
  }
</script>
