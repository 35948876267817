<script>
  import * as jwt from 'jsonwebtoken'

  export default {
    props: {
      token: {
        type: String,
        required: true,
      },
    },
    data() {
      return {}
    },
    mounted() {
      const decode = jwt.verify(this.token, process.env.JWT_SECRET ?? 'agenda', { algorithms: ['HS256'] })
    },
  }
</script>
<template>
  <div>test</div>
</template>
